// ##### 23-08-29 Komarov: Файл для размещения всех CSS-файлов для приложения: стоит переместить их из конкретных Компонентов сюда, чтобы можно было объединить код Клиентской и Серверной частей приложения. А CSS отсюда попадёт в приложение так же, как и из отдельных Компонентов: в процессе билда приложения все файлы всё равно собираются в объединённые файлы CSS.
import "./Components/Library/Authorization/Login.css";
import "./Components/Library/Icons/homeicon.css";
import "./Components/Library/Icons/pax.css";
import "./Components/Library/Localization/LangSelectionFlagsCSS.css";
import "./Components/Library/PageDevices/Animation/AboutUsCSS.css";
import "./Components/Library/PageDevices/Animation/CupOfCoffee.css";
import "./Components/Library/PageDevices/Animation/yearanimationCSS.css";
import "./Components/Library/PageDevices/CartGallery/CartGalleryCSS.css";
import "./Components/Library/PageDevices/CartGallery/CartGalleryShortVersionCSS.css";
import "./Components/Library/PageDevices/ClearInputCSS.css";
import "./Components/Library/PageDevices/Forms/GroupTravel/GroupTravelFormCSS.css";
import "./Components/Library/PageDevices/Forms/PartnershipForm/PartnershipFormCSS.css";
import "./Components/Library/PageDevices/Forms/SupplierPartnerFormCSS.css";
// ##### 24-12-19 Komarov
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Components/Library/PageDevices/Forms/FormCSS.css";
import "./Components/Library/PageDevices/HotelsPaxChoiceCSS.css";
import "./Components/Library/PageDevices/LoadingMessage.css";
import "./Components/Library/Slider/SliderCss.css";
import "./Components/Pages/BookingForm/BookingForm.css";
import "./Components/Pages/BookingForm/CartDetails.css";
import "./Components/Pages/BookingForm/ClientDetailsCSS.css";
import "./Components/Pages/BookingForm/ConfirmButton.css";
import "./Components/Pages/BookingForm/OfflineSummary.css";
import "./Components/Pages/BookingForm/TermsConditionsCSS.css";
import "./Components/Pages/ChangeBooking/CanxBookingCSS.css";
import "./Components/Pages/CustomizedPages/RegistrationRotaryCSS.css";
import "./Components/Pages/FirstPageofSearchModule/HotelsAutocompleteBlock/HotelsAutocompleteCSS.css";
import "./Components/Pages/FirstPageofSearchModule/HotelsAutocompleteBlock/PromoCodeCSS.css";
import "./Components/Pages/FirstPageofSearchModule/SearchResizersAndSwitchers/Search.css";
import "./Components/Pages/FirstPageofSearchModule/SearchResizersAndSwitchers/SmallScreenSearchCSS.css";
import "./Components/Pages/FirstPageofSearchModule/SearchResizersAndSwitchers/SwitcherFront.css";
import "./Components/Pages/LiqPay/CartDetailsSummary.css";
import "./Components/Pages/PageComponents/Footer/CityHotelsCSS.css";
import "./Components/Pages/PageComponents/HomePage/TopToursCSS.css";
import "./Components/Pages/PageComponents/HomePage/TopToursDetailsCSS.css";
import "./Components/Pages/PageComponents/MenuPageGeneratorCSS.css";
import "./Components/Pages/PageComponents/ResponsiveHeader/header.css";
import "./Components/Pages/RoutesAndLinks/footer.css";
import "./Components/Pages/SecondPageofSearchModule/Hotels/HotelItemsCSS.css";
import "./Components/Pages/SecondPageofSearchModule/Tours/SearchItems.css";
import "./Components/Pages/ThirdPageofSearchModule/Hotels/BookButtonHotelCSS.css";
import "./Components/Pages/ThirdPageofSearchModule/Hotels/HotelDetailsCSS.css";
import "./Components/Pages/ThirdPageofSearchModule/Hotels/RatesBlock/RatesBlockCSS.css";
import "./Components/Pages/ThirdPageofSearchModule/TourDetailsCSS.css";
import "./Components/Pages/ThirdPageofSearchModule/Tours/TourDetailsCSS.css";

